/* HeaderMenu.css */
.header-menu {
    position: absolute;
    top: calc(100% + 1px); /* Adjust this value to close any gaps */
    right: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    border-radius: 10px;
    padding: 0;
    z-index: 100;
    padding-top: 0; /* Adjust this as needed to close any gap */
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Simplified transitions */
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px); /* Start slightly above */
    will-change: transform, opacity, padding;
    justify-items: center;
    display: flex;
    flex-direction: column; /* Align items in a column */
    align-items: center; /* Center items horizontally */
    pointer-events: none;
    justify-content: flex-start; /* Align content to the top */
    opacity: 1;
    background: radial-gradient(circle at center, var(--main-color),  rgba(0, 0, 0, 0.1));
    backdrop-filter: blur(2px); /* This blurs only the background */
    -webkit-backdrop-filter: blur(2px); /* For Safari */
    width: auto; /* Adjust the width automatically to content */
    min-width: 100px; /* Set a minimum width to handle most cases */
    margin-top: 0;
    border: 1px solid rgba(255, 255, 255, 0.1); 
  }

.header-menu.active {
    margin-top: 4%;
    max-height: 500px; /* Adjust based on content */
    opacity: 1;
    visibility: visible;
    transform: translateY(0); /* Slide down into final position */
    justify-items: center;
    align-items: center;
    padding-top: 1rem; /* Add padding to the top */
    padding-bottom: 1rem; /* Add padding to the bottom */
    pointer-events: auto;
    transition-delay: 0s;
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease; /* Simplified transitions */
   
   
}
.menu-item:hover > .header-menu,
.header-menu:hover {
  max-height: 500px; /* Adjust based on content */ 
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  pointer-events: auto;
  transition-delay: 0s;
  transition: none;
}

.header-menu-item {
    opacity: 0;
    padding: 0;
    margin: 10px;
    transition-delay: 0s;
    transition: opacity 1 0.5s ease-in-out, transform 0.3s ease-out, visibility 0.3s ease-in-out;
    visibility: hidden; /* Initially hidden */
    transform: translateY(-10px);
    display: flex;
    align-items: center; /* Center items vertically */
    width: 100%; /* Full width to align text and icons */
    min-width: 150px; /* Or whatever minimum width you find suitable */
}
.header-menu-item {
    position: relative; /* This ensures the absolute positioning of the dropdown is relative to the menu item */
  }
  
.header-menu.active .header-menu-item {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s ease-in-out, transform 0.5s cubic-bezier(0, 1.8, 1, 1.8);
    transition-delay: 0s; /* Delay to sync with the dropdown */
    visibility: visible; /* Make visible when active */
}

/* Hover effect for each item */
.header-menu-link {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease-out, transform 0.2s ease-out;
    width: 100%; /* Take full width for easier click */
    display: flex;
    opacity: 1;
    align-items: center;
    gap: 10px; /* Space between icon and text */
    padding: 0.5rem 1rem; /* Add padding for better touch */
    word-break: break-word;
    white-space: nowrap; /* Prevent text from wrapping */
}


.header-menu-link:hover {
    color: var(--color-dark-purple); /* Change the color on hover */
    transform: translateX(5px) scale(1.05); /* Slight right movement and scaling on hover */
  }
.header-menu-icon {
    fill: currentColor; /* Icon color matches the text color */
    transition: fill 0.3s ease-out; /* Smooth color transition for the icon */
  }

/* Example of using an icon in the menu */
.header-menu-link .header-menu-icon {
    width: 1em; /* Icon size relative to font size */
    height: 1em; /* Maintain aspect ratio */
  }

.header-menu-link::before {
    content: '';
    display: inline-block;
    background-size: cover;
    width: 1em;
    height: 1em;

  }

@media only screen and (max-width: 768px) {
  .header-menu {
    width: 100%;
    top: 100%;
    right: 0;
    left: 0;
    padding-top: 0.5rem;
  }

  .header-menu-item {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (768 - 320)));
    padding: 0.5rem 1rem;
  }

  .header-menu-link {
    gap: 5px;
    padding: 0.75rem 1rem;
  }

  .header-menu-icon {
    width: 1.2em;
    height: 1.2em;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1920px) {
    .header-menu {
      width: auto;
      top: calc(100% + 5px);
      right: 0;
      padding-top: 0.75rem;
    }
  
    .header-menu-item {
      font-size: 18px;
      padding: 0.5rem 1rem;
    }
  
    .header-menu-link {
      gap: 8px;
      padding: 0.5rem 1rem;
    }
  
    .header-menu-icon {
      width: 1em;
      height: 1em;
    }
  }
  