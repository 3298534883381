  /* Logo container with padding to prevent it from being cut off */
.logo-container {
    padding: 0; /* Prevents the logo from touching the screen edges */
    flex-grow: 1; /* Allows the logo container to take up available space */
    display: flex; /* Enables positioning of the logo */
    justify-content: center; /* Centers the logo horizontally */
    align-items: center; /* Centers the logo vertically */
    margin: 1rem;
    width: 3rem;
    max-width: calc(100% - 3rem); /* Subtracts the space taken by the menu icon and potential additional padding */
  }
  .logo-container-iphone {
    padding: 0; /* Prevents the logo from touching the screen edges */
    flex-grow: 1; /* Allows the logo container to take up available space */
    display: flex; /* Enables positioning of the logo */
    justify-content: flex-start; /* Centers the logo horizontally */
    align-items: flex-start; /* Centers the logo vertically */
    margin: 1rem;
}

.logo-container-iphone img {
    max-width: 50px; /* Example size; adjust as needed */
    max-height: 50px; /* Example size; adjust as needed */
    width: auto; /* Maintain aspect ratio */
    height: auto; /* Maintain aspect ratio */
}
