/* ContactPage.css */
.contact-section {
  text-align: center;
  padding: 2rem;

  border-radius: 10px;
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-width: 800px;
  margin: auto;
}

.contact-form {
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  width: 100%;
}

.contact-form label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--color-light-purple);
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  color: var(--color-darkest-purple);
  border: 1px solid #ddd;
  background-color: var(--color-lightest-purple);
  border-radius: 4px;
}

.contact-form button {
  background-color: var(--color-dark-purple); /* Adjust button color as needed */
  color: var(--color-lightest-purple);
  padding: 0.7rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: var(--color-darkest-purple); /* Adjust button hover color as needed */
}

.contact-info {
  text-align: center;
  color: var(--color-lightest-purple);
}

.contact-info h2 {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }

  .contact-form, .contact-info {
    width: 100%;
  }
}
