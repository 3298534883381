/* Footer.css */

.footer {
  background-color: var(--main-color); /* Adjust the color as needed */
  color: white;
  text-align: center;
  padding: 10px;
  position: relative; /* Changed from absolute to relative */
  bottom: 0px;
  width: 100vw;
  z-index: 10000000000;
}

.footer a {
  color: #61dafb; /* Adjust link color as needed */
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer-content {
  margin: auto;
  max-width: 960px;
  padding: 10px;
}
@media (max-width: 480px) {
  .footer {
    width: fit-content;
  }
}