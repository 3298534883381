/* waitlistPage.css */
.waitlist-section {
    text-align: center;
    border-radius: 10px;
  }
  .waitlist-section h1, h2, h3 {
    color: var(--color-light-purple)
  }
  .waitlist-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 800px;
    margin: auto;
  }
  
  .waitlist-form {
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }
  
  .waitlist-form label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--color-light-purple);
    font-weight: bold;
  }
  
  .waitlist-form input,
  .waitlist-form textarea {
    padding: 0.5rem;
    margin-bottom: 1rem;
    color: var(--color-darkest-purple);
    border: 1px solid #ddd;
    font-family: Poppins;
    background-color: var(--color-lightest-purple);
    border-radius: 4px;
  }
  .waitlist-form textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    color: var(--color-darkest-purple);
    border: 1px solid #ddd;
    background-color: var(--color-lightest-purple);
    border-radius: 4px;
    resize: none; /* This will prevent resizing */
}

  .waitlist-form button {
    background-color: var(--color-dark-purple); /* Adjust button color as needed */
    color: var(--color-lightest-purple);
    padding: 0.7rem 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: Poppins;
    transition: background-color 0.3s;
  }
  
  .waitlist-form button:hover {
    background-color: var(--color-darkest-purple); /* Adjust button hover color as needed */
  }
  
  .waitlist-info {
    text-align: center;
    color: var(--color-lightest-purple);
  }
  
  .waitlist-info h2 {
    margin-bottom: 1rem;
  }
  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
.checkbox-label {
    margin-left: 10px; /* Reduced margin */
    white-space: nowrap;
}
@media (min-width: 768px) {
  .checkbox-container {
    width: fit-content; /* Full width */
  }

}
@media (max-width: 480px) {
    .waitlist-container {
        flex-direction: column;
        margin-right: 15px;
        margin-left: 15px;
    }
    .checkbox-container {
      width: 100%; /* Full width */
    }
    .checkbox-label {
        margin-left: 10px;
        margin-right: 50%;
        font-size: 14px; /* Optionally reduce the font size */
    }

    .waitlist-form, .waitlist-info {
        width: 80%; /* Adjust to 100% for full width */
    }
}
