/* pricing.css */
.pricing-header {
    text-align: center;
    padding: 20px;
    margin-bottom: 30px;
}

/* Styling for Plan Containers */
.plan-container {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 20px;
    
}
@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animated-fadeIn {
    animation: fadeInOut 2s ease-in forwards;
  }
/* Styling for Each Plan */
.free-plan,
.individual-plan,
.small-business-plan {
    color: var(--color-lightest-purple);
    background: radial-gradient(circle at center, var(--main-color), rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}
/* General Styling for the Pricing Section */
.pricing-header,
.free-plan,
.individual-plan,
.small-business-plan,
.why-choose-us,
.faqs,
.call-to-action {
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Styling for Each Plan */
.free-plan,
.individual-plan,
.small-business-plan {
    text-align: center;
}
.button-primary {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    bottom: 1rem;
    border: none;
    border-radius: 5px;
    background-color: var(--color-dark-purple);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .button-primary:hover {
    background-color: var(--color-light-purple);
  }
/* Plan Title Styling */
.free-plan h2,
.individual-plan h2,
.small-business-plan h2 {
    color: var(--color-light-purple);
    margin-bottom: 15px;
}

/* List and Price Styling */
.free-plan ul,
.individual-plan ul,
.small-business-plan ul,
.free-plan p,
.individual-plan p,
.small-business-plan p {
    text-align: left;
    margin-left: 20px;
}

/* Responsive Design for Mobile Devices */
@media (max-width: 768px) {
    .free-plan,
    .individual-plan,
    .small-business-plan {
        width: 100%;
        margin: 10px 0;
    }
}


/* Icon Styling */
.plan-container .mdi-icon {
    color: var(--color-light-purple);
    margin-bottom: 10px;
}

/* Improved List Style */
.plan-container ul {
    list-style-type: none;
    padding: 0;
}

.plan-container li {
    text-align: center;
    margin: 5px 0;
}

/* Hover Effect on Each Plan */
.free-plan:hover,
.individual-plan:hover,
.small-business-plan:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    /* Smoother Transition */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Initial State Transition */
.free-plan,
.individual-plan,
.small-business-plan {
    /* Apply Transition to Initial State */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Enhance Mobile Responsiveness */
@media (max-width: 768px) {
    .plan-container {
        flex-direction: column;
    }

    /* Adjust Hover Effect for Mobile */
    .free-plan:hover,
    .individual-plan:hover,
    .small-business-plan:hover {
        transform: none;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }
}

/* Enhance Mobile Responsiveness */
@media (max-width: 768px) {
    .plan-container {
        flex-direction: column;
    }
}
/* Highlight and Bold Styling */
.highlight {
    color: var(--color-light-purple); /* Choose a secondary color that suits your theme */
    font-weight: bold;
}

.bold {
    font-weight: bold;
}

/* Styling for Price */
.price {
    font-size: 1.1em;
    color: var(--color-dark-purple);
    font-weight: bold;
}

/* Styling for List Items with Icons */
ul {
    list-style: none;
    padding-left: 0;
    
}

li {
    display: flex;
    align-items: center;
}

li svg {
    margin-right: 20px;
}
.sign-up-button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 5px;
    background-color: var(--color-dark-purple);
    color: white;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
  }
.sign-up-button:hover {
    background-color: var(--color-darkest-purple);
}
.call-to-action-wrapper {
    text-align: center; /* Center align content */
    padding: 20px;
    margin-bottom: 30px;
}
.list-icon {
    margin-top: 20px;
}