/* scr/styles/App.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.App {
  text-align: center;
  font-family: 'Poppins', sans-serif; /* Apply the Poppins font */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 1000; /* Ensure the header is above other content */
  position: relative; /* z-index only works on positioned elements */
}

.App-link {
  color: var(--color-dark-purple);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  font-family: 'Poppins', sans-serif; /* Apply the Poppins font globally */
  color: white;
  margin: 0;
  padding: 0;
  transition: background-color 0.3s;
}
/* Global styles */
:root {
  --main-color: #090014; /* No change, as requested */
  --color-light-purple: #b39ddb; /* A lighter purple for contrast against dark */
  --color-dark-purple: #56479c; /* A deep purple that contrasts well with light purple */
  --color-lightest-purple: #ede7f6; /* Very light purple, almost white, for backgrounds */
  --color-darkest-purple: #1a003c; /* No change, this should work well with your main color */
}
@media only screen and (min-width: 1280px) {
  body {
    background: radial-gradient(circle at center, var(--color-darkest-purple),  var(--main-color));
  }
}
@media only screen and (max-width: 540px) {
  body {
    background: var(--main-color)
  }
}

a {
  color: inherit;
  text-decoration: none;
}



/* Glass-like styles */
.glass {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 20px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.glass:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

/* Adding some basic animations */


.fadeIn {
  animation: fadeIn 1s ease-in-out forwards;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.parent {
  perspective: 1000px;
}

.child {
  transform: translateZ(100px); /* moves the element 100px closer to the viewer */
}
.main-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Set minimum height to full viewport height */
  max-width: 100vw;
  justify-content: space-between; /* This pushes the footer down */
}