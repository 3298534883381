/* src/styles/StarBackground.css */

.star {
    width: 2px;
    height: 2px;
    background: white;
    position: absolute;
    border-radius: 50%;
    animation: twinkle 2s infinite ease-in-out, drift 5s infinite alternate ease-in-out;
}

/* Existing twinkle animation */
@keyframes twinkle {
    0%, 100% { opacity: 0.5; }
    50% { opacity: 1; }
}

/* New drift animation for subtle movement */
@keyframes drift {
    0%, 100% { transform: translate(0, 0); }
    50% { transform: translate(4px, 4px); } /* small 2px movement */
}

.stars {
    position: fixed;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1; /* Ensure stars are behind other content */
}

.warp .star {
    animation: streak 1s forwards, twinkle 2s infinite ease-in-out;
    filter: blur(2px); /* Adjust blur value as needed */
  }
  
  @keyframes streak {
    0% { transform: translate(0); filter: blur(0px); }
    100% { transform: translateX(500px); filter: blur(15px); } /* Adjust for desired effect */
  }