/* src/styles/About.css */

/* AboutValues.css */

.values-section h1 {
  text-align: center;
  margin-bottom: 2rem;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated-fadeIn {
  animation: fadeInOut 2s ease-in forwards;
}
.values-section p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}
.values-left-aligned {
  text-align: left;
  justify-content: start;
  margin-right: 180px;
  margin-left: 20px;
}
.values-left-aligned-extra {
  text-align: left;
  justify-content: start;
  margin-right: 240px;
  margin-left: 20px;
}
/* You can adjust the margins as needed to align with the layout of AboutUs */
.values-right-aligned {
  text-align: right;
  justify-content: end;
  margin-left: 180px;
  margin-right: 20px;
}

.values-right-aligned-extra {
  text-align: right;
  justify-content: right;
  margin-left: 200px;
  margin-right: 20px;
}
.values-center {
  text-align: left;
  justify-content: left;
  margin-left: 20px;
  margin-right: 20px;
}
/* Add additional styles for positioning images */
.values-image-left {
  float: left; /* Aligns the image to the left */
  width: 200px; /* Adjust the size as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 20px; /* Spacing between image and text */
  margin-top: 15px;
  animation: fadeIn 2s ease-in-out infinite alternate, hover-animation 2s ease-in-out infinite alternate;
}

.values-image-right {
  float: right; /* Aligns the image to the right */
  width: 200px; /* Adjust the size as needed */
  height: auto; /* Maintain aspect ratio */
  margin-left: 20px; /* Spacing between image and text */
  animation: fadeIn 2s ease-in-out infinite alternate, hover-animation 2s ease-in-out infinite alternate;
}
.values-right-aligned,
.values-right-aligned-extra,
.values-left-aligned,
.values-left-aligned-extra {
  text-align: inherit;
  position: relative;
}

/* Pseudo-elements for left and right padding simulation */
.values-left-aligned::before,
.values-left-aligned-extra::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 10px; /* Adjust this value based on desired padding from image */
  height: 100%;
  background-color: transparent; /* Same as the background; it will cover text that gets too close to the image */
}

.values-right-aligned::after,
.values-right-aligned-extra::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 10px; /* Adjust this value based on desired padding from image */
  height: 100%;
  background-color: transparent; /* Same as the background; it will cover text that gets too close to the image */
}

/* Clear floats after the .values-content elements */
.values-content::after {
  content: "";
  display: table;
  clear: both;
}
.values-left-aligned, .values-left-aligned-extra,
.values-right-aligned, .values-right-aligned-extra {
  display: block; /* This makes it easier to manage spacing around the p element */
  overflow: visible; /* This ensures that the text does not flow behind the image */
}
@keyframes hover-animation {
0%, 100% {
  transform: translateY(0);
}
50% {
  transform: translateY(-5px); /* subtle move up */
}
}

@keyframes fadeIn {
from {
  opacity: 0.9; /* less transparent */
}
to {
  opacity: 1; /* less transparent */
}
}
/* Use flexbox for layout */
.values-section {
  display: flex;
  flex-direction: column; /* Stack elements vertically by default */
}

/* Responsive Image */
.responsive-image {
  width: 100%; /* Full width */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 1rem; /* Space below image */
}

@media (min-width: 768px) { /* Define breakpoint for tablets and above */
  /* Switch to horizontal layout */

  /* Larger images for desktop */
  .responsive-image {
      max-width: 50%; /* Half width */
      margin-bottom: 0; /* Remove margin if necessary */
  }

  /* Text alignment for larger screens */


}

@media (max-width: 350px) {
  /* Adjust text alignment and margins for very small screens */
  .values-left-aligned,
  .values-left-aligned-extra {
      text-align: left;
  }
  .values-left-aligned,
  .values-left-aligned-extra,
  .values-right-aligned,
  .values-right-aligned-extra,
  .values-center {
      margin-left: 10px; /* Smaller margins to utilize space */
      margin-right: 10px;
  }

  /* Adjust font sizes for better readability on small screens */
  .values-section h1 {
      font-size: 1.5rem; /* Slightly larger heading for visibility */
  }

  .values-section p {
      font-size: 0.9rem; /* Slightly smaller paragraph font size */
  }

  /* Adapt image styles for small screens */
  .values-image-left,
  .values-image-right {
      float: none; /* Remove float for small screens */
      display: block; /* Stack images */
      width: 80%; /* Make images take up most of the container width */
      margin: 0 auto 1rem auto; /* Center images and add margin below */
      animation: none; /* Optional: remove animations on small screens */
  }

  /* Clear any additional styles that might affect small screens */
  .values-content::after {
      content: none; /* Clear the floats for small screens */
  }
}
/* This ensures that the body does not overflow horizontally */
/* General styles to prevent overflow and ensure padding */
body {
  overflow-x: hidden; /* Prevents horizontal scrolling */
  margin: 0; /* Resets default margin */
  padding: 5px; /* Adds padding around the entire content of the page */
  box-sizing: border-box; /* Ensures padding does not affect the total width */
}

/* Styles for .values-section paragraphs */
.values-left-aligned,
.values-left-aligned-extra,
.values-right-aligned,
.values-right-aligned-extra,
.values-center {
  margin: 10px; /* Ensures margin from the edges and images */
  word-wrap: break-word; /* Ensures text breaks to prevent overflow */
}

/* Styles for images to prevent text overlap */
.values-image-left,
.values-image-right {
  width: 200px; /* Adjust the size as needed */
  height: auto; /* Maintain aspect ratio */
  margin: 10px; /* Spacing between image and text */
  display: inline-block; /* Align images inline with text */
  vertical-align: top; /* Align images to the top of the text */
}

/* Clear floats and ensure content does not flow behind images */
.values-content::after {
  content: "";
  display: table;
  clear: both;
}

/* Media query for very small screens */
@media (max-width: 350px) {
  .values-left-aligned,
  .values-left-aligned-extra,
  .values-right-aligned,
  .values-right-aligned-extra,
  .values-center {
      margin: 10px; /* Adjusted margin for smaller screens */
      font-size: 0.9rem; /* Smaller font size for readability */
  }

  .values-image-left,
  .values-image-right {
      width: 150px; /* Adjust width to ensure it fits within the margins */
      margin-top: 1rem; /* Spacing above the image */
      margin-bottom: 1rem; /* Spacing below the image */
      float: none; /* Disable floating for images */
  }
}

/* Media query for slightly larger screens, like iPhone X and above */
@media (max-width: 417px) {
  /* Adjust text alignment and margins for larger phones */
  .values-left-aligned-extra {
      text-align: justify;
  }
  .values-left-aligned,
  .values-left-aligned-extra,
  .values-right-aligned,
  .values-right-aligned-extra,
  .values-image-left,
  .values-image-right,
  .values-center {

      margin-left: 15px; /* Ensures margin from the edge of the screen */
      margin-right: 15px;

  }

  /* Update image styles for larger phones */
  .values-image-left {
      width: 100px;

  }
  .values-image-right {
      width: 150px; /* Full width minus margin */
      margin: 0 auto 1rem auto; /* Center images and add margin below */
  }

  /* Other elements such as lists, buttons, etc., should also respect the margin */
  .content-container {
      padding-left: 5px; /* Ensures padding inside the container */
      padding-right: 5px;
  }
}

/* General styles for the about section */
.about-page-section {
  /* Centering content and adding margins for smaller screens */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  margin-bottom: 2rem;
}

/* Styles for foundation and principles sections */
.foundation-section,
.principles-section {
  display: flex;
  flex-direction: column; /* Stack elements vertically on small screens */
  align-items: center; /* Center align items */
  margin-bottom: 2rem; /* Space below each section */
}

/* Styling paragraphs to align text to the left on mobile */
.foundation-section p,
.principles-section p {
  text-align: left;
}

/* Styling for images in the sections */
.foundation-section img,
.principles-section img {
  width: 85%; /* Full width for mobile, adjust as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 1rem; /* Space below image */
}

/* Style adjustments for links */
.foundation-section a,
.principles-section a {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--color-light-purple);
  color: var(--color-darkest-purple);
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

/* Hover effects for links */
.foundation-section a:hover,
.principles-section a:hover {
  background-color: var(--color-dark-purple);
  color: var(--color-lightest-purple);
}

/* Media queries for responsiveness */

/* Tablets and desktops */
@media (min-width: 768px) {
  .foundation-section,
  .principles-section {
    flex-direction: row; /* Side-by-side layout */
    align-items: flex-start;
    text-align: justify;
  }

  .foundation-section img,
  .principles-section img {
    width: 30%; /* Adjust image size for larger screens */
    margin-right: 2rem; /* Space between image and text */
  }

  /* Reversing the order for the foundation section to put the image on the left */
  .foundation-section {
    flex-direction: row-reverse;
  }

  .principles-section img {
    margin-left: 2rem; /* Space between image and text */
    margin-right: 0;
  }
}

/* Smaller smartphones */
@media (max-width: 480px) {
  .foundation-section img,
  .principles-section img {
    align-content: center;
    width: 85%; /* Adjust for very small screens */
  }
}
  .principles-section img {
    margin-right: 30px;
  }