@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');


/* MobileHeader.css */
body.menu-open {
    position: fixed; /* Keep the body in place */

  }
  
  
.mobile-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: transparent;
    color: #fff;
    overflow-x: hidden;
    position: fixed;
    transition: transform 0.3s ease-in-out;
    background: radial-gradient(circle at center, var(--main-color), rgba(0, 0, 0, 0.6));
  }
  
  /* Styles for the menu icon */
  .menu-icon {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end; /* Aligns the menu icon to the right */
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    background: transparent;
    border: none;
    outline: none;
    z-index: 10; /* Ensures the icon is above other elements */
  }
  
  .menu-icon span {
    display: contents;
    width: 3rem; /* Matches the width of the parent */
    height: 0.25rem; /* Thickness of the hamburger lines */
    background: #fff; /* Color of the hamburger lines */
    border-radius: 10px; /* Makes the lines rounded */
    position: relative;
    transition: opacity 0.3s, transform 0.3s;
  }
  
  /* Animation for the hamburger icon into 'X' */
  .menu-icon.open span:nth-child(1) {
    transform: rotate(45deg);
  }
  
  .menu-icon.open span:nth-child(2) {
    opacity: 0;
  }
  
  .menu-icon.open span:nth-child(3) {
    transform: rotate(-45deg);
  }
  
  /* Style for when the menu is open */
  .mobile-header-menu {
    display: none; /* Hidden by default */
    left: 0;
    width: 100%; /* Full width */
    background: radial-gradient(circle at center, var(--main-color),  rgba(0, 0, 0, 0.7));
    backdrop-filter: blur(10px); /* This blurs only the background */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
    top: 9%; /* Adjusted to overlay from the top of the viewport */
    height: 100%; /* Full viewport height */
    opacity: 0; /* Start hidden */
    visibility: hidden; /* Start invisible */
    transition: opacity .1 0.3s ease, visibility 0.3s ease; /* Smooth transition for opacity and visibility */
    overflow-x: hidden;

  }
  
  /* Open state for the menu */
  .mobile-header-menu.open {
    display: flex; /* Show the menu */
    flex-direction: column; /* Stack items vertically */
    padding: 1rem; /* Add padding for touch targets */
    animation: slideDown 0.5s ease forwards;
}


  .mobile-header-menu-item:hover,
  .mobile-header-menu-item:focus {
    background-color: #666; /* Feedback color for hover and focus */
  }
  
  /* Responsive adjustments for smaller screens */
@media only screen and (max-width: 320px) {
  .mobile-header {
    padding: 0.5rem; /* Reduced padding for very small screens */
  }

  
  .menu-icon span {
    width: 1.5rem; /* Smaller menu icon on very small screens */
  }
}
/* Animation for the menu to slide in from the top */
@keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  /* Apply the animation when the menu is in the open state */
  /* Style for when the menu is open */
  .mobile-header-menu.open {
      display: block; /* Show the menu */
      position: fixed; /* Stay in place when scrolling */
      overflow-x: hidden;
      overflow-y: auto; /* Enable vertical scrolling */
      background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
      z-index: 9999; /* Make sure it's on top of other content */
      transition: all 0.3s ease-in-out; /* Smooth transition for opening and closing */
      visibility: visible; /* Make it visible when open */
    }
  
  
  /* Additional styles for submenu items */
  .mobile-menu-item {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0.75rem 0; /* Increased margin to add more space between items */
    border-bottom: 1px solid #ccc; /* Thin light grey line for separation */
    padding-bottom: 0.75rem; /* Adjust padding to balance the border */
}
  
  .mobile-menu-item-icon {
    margin-right: 10px; /* Space between icon and text */
  }
/* Menu icon animated into 'X' */
.menu-icon.active {
    justify-content: center; /* Center the icon lines */
  }
  
  .menu-icon.active span {
    position: absolute;
    transition: all 0.3s ease;
  }
  
  .menu-icon.active span:nth-child(1) {
    transform: rotate(45deg);
  }
  
  .menu-icon.active span:nth-child(2) {
    width: 0; /* Hide the middle line */
  }
  
  .menu-icon.active span:nth-child(3) {
    transform: rotate(-45deg);
  }

  .menu-section-title:last-child {
    border-bottom: none; /* No divider for the last item */
  }
/* Style for the caret icon to align it to the right */
.menu-section-title Icon {
    margin-left: auto; /* This pushes the icon to the far right */
    margin-right: 15px; /* 15px margin from the right */
  }
  
  .submenu-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .submenu-open {
    max-height: 500px; /* Adjust as needed for your content */
    transition: max-height 0.5s ease-in;
  }
/* Style for the section titles in the mobile menu */
.menu-section-title {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    justify-content: flex-start; /* Aligns title to left, icon to right */
    padding: 1rem 30px; /* 15px padding to the right for the icon */
    border-bottom: 1px solid #ccc; /* Divider line */
    width: 80%;
    overflow-x: hidden;
    /* Modern font and bold text */
    font-family: 'Poppins', sans-serif; /* This is the modern font */
    font-weight: 700; /* Makes the font bold */
  }
  
  /* Correct the Icon component style for the caret icons */
  .mdi-icon-right { /* Assuming .mdi-icon is the class your Icon component renders */
    margin-left: auto; /* Pushes the icon to the right */
    margin-right: 3rem; /* 15px margin from the right edge */
    flex-direction: flex-end;
    justify-content: flex-end;
  }
  .mdi-icon-left { /* Assuming .mdi-icon is the class your Icon component renders */
    margin-left: 3rem; /* Pushes the icon to the right */
    margin-right: auto; /* 15px margin from the right edge */
    flex-direction: flex-start;
    justify-content: flex-start;
  }
  /* Ensure menu items take full width */
  .mobile-header-menu-item {
    display: block;
    width: 100%; /* Full width to allow easy tapping */
    padding: 0.75rem 1rem; /* Padding for tap targets */
    margin: 0.25rem 0; /* Space out menu items */
    text-align: left; /* Align text to the left */
    color: #fff; /* Text color */
    text-decoration: none; /* No underlines on links */
    transition: background-color 0.3s; /* Transition for feedback on tap */
    overflow-x: hidden;
    border-radius: 10px;
    
    /* Modern font and bold text */
    font-family: 'Poppins', sans-serif; /* This is the modern font */
    font-weight: 700; /* Makes the font bold */
  }
  
  /* Ensure the submenu container takes full width */
  .submenu-container {
    text-align: center;
    margin-left: 15px; /* Full width of the parent container */
  }
/* New styles for when the menu is open */
.mobile-header-expanded {
    background: radial-gradient(circle at center, var(--main-color),  rgba(0, 0, 0, 0.8));
    transition: background-color 0.3s ease, opacity 0.3s ease; /* Smooth transition */
    overflow-x: hidden;
  }
  
  /* Ensure the header transitions even when closing */
  .mobile-header {
    transition: background-color 0.3s ease, opacity 0.3s ease; /* Smooth transition for the header too */
  }
  
  /* Update your animation for the menu */
  @keyframes slideDown {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: .7;
      visibility: visible;
    }
  }
@media only screen and (min-width: 1280px) {
  body.menu-open {
    overflow: hidden; /* Optional: Prevent scrolling while menu is open */
    backdrop-filter: blur(10px); /* Apply the blur effect */
    overflow-x: hidden;
  }
}
.mobile-header-menu.open {
    z-index: 100; /* or any value above the backdrop filter z-index */
    overflow-x: hidden;
  }
/* Hide the header when scrolled down */
.mobile-header-hidden {
    transform: translateY(-100%); /* Hide the header above the viewport */
    transition: transform 0.3s ease-in-out; /* Smooth slide-up animation */
  }
  
  /* Show the header when scrolled up */
  .mobile-header-shown {
    transform: translateY(0); /* Bring the header back into the viewport */
    transition: transform 0.3s ease-in-out; /* Smooth slide-down animation */
  }
  
  /* Fixed header */
  .mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10; /* Ensure it's above other content */
  }
