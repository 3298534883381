.header {
  position: fixed; /* Fixed at the top */
  top: 0;
  left: 0;
  width: 100%;
  transition: top 0.3s ease-in-out; /* Smooth transition for sliding in and out */
  display: flex;
  margin-top: 1%;
  justify-content: flex-start; /* Changed from flex-end to flex-start to move items to the left */
  align-items: center; /* This centers the items vertically. You can use 'flex-start' to move them to the top or 'flex-end' to move them to the bottom */
  padding-left: 30%; /* Added padding-left to move items to the left */
  padding-right: 10%;
  padding-bottom: 1%;
  margin-left: 15%;
  z-index: 1000; /* Ensure the header is above other content */
}

.header div {
  font-size: '24px';
  font-weight: '600'; 
}
.header.hidden {
  top: -100%; /* Slide out of view */
}


.header span {
  display: inline-block;
  margin-left: 5px;
  transition: transform 0.3s ease-out, color 0.2s ease-in-out;
  cursor: pointer;
}

.header span.caret {
  transform: scaleY(-1);
  transform-origin: center bottom;
}
.menu-item {
  cursor: pointer;
}
.header .caret {
  transform-origin: 50% 75%; /* Adjust as necessary */
  transition: transform 0.3s ease-out;
}


.header .caret.flipped {
  /* Rotate the caret when the menu is active */
  transform: rotate(180deg);
}
/* Hover effect for the span */
.header span:hover {
  color: var(--color-dark-purple); /* Change the color on hover */
  transform: translateY(-3px); /* Move up slightly */
  transition: transform 0.2s ease-in, color 0.2s ease-in-out;
}



/* Keyframes for the pop animation */
@keyframes pop {
  0% {
    transform: scale(0.98);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.header .active {
  animation: pop 0.25s ease forwards; /* Shorten the duration */
  color: var(--color-light-purple)
}
@media only screen and (max-width: 768px) {
  .header {
    padding-left: 5%;
    margin-left: 0;
    justify-content: space-between;
  }

}
@media only screen and (min-width: 769px) and (max-width: 1920px) {
  .header {
    padding-left: 10%;
    margin-left: 5%;
  }
}
