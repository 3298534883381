/* BlurOverlay.css */
/* This is a conceptual example and will need to be adjusted to fit your existing styles. */
.blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent; /* Adjust the color and opacity as needed */
    z-index: 100; /* Ensure this is below the z-index of the header */
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    height: 10000%;
  }
  .blur-overlay:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent; /* Adjust the color and opacity as needed */
    z-index: 100; /* Ensure this is below the z-index of the header */
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    height: 10000%;
  }
  .mobile-header {
    position: relative;
    z-index: 200; /* Higher than the blur overlay to stay on top */
  }
  